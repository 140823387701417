import { Avatar, CButton, CSButton, CBackButton, CRouterLink, CLink, FeatherIcon } from '@ezio/ui'

import { App } from 'vue'

import Centered from '@ezio/ui/layouts/Centered.vue'
import Main from '@/layouts/Main.vue'
import Single from '@/layouts/Single.vue'
import Organization from '@/layouts/Organization.vue'

export const initComponents = (app: App) => {
    app.component('avatar', Avatar)
    app.component('CButton', CButton)
    app.component('CSButton', CSButton)
    app.component('CLink', CLink)
    app.component('CRouterLink', CRouterLink)
    app.component('CBackButton', CBackButton)
    app.component('FeatherIcon', FeatherIcon)
}

export const initLayouts = async (app: App) => {
    app.component('Centered', Centered)
    app.component('Main', Main)
    app.component('Single', Single)
    app.component('Organization', Organization)
}
