import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useEzioAPI, useEzioNodeAPI } from '@ezio/services'
import { useEzioWs } from '@ezio/services'
import { useTokensStore } from '@ezio/features/tokens'
import { BaseError } from '@ezio/utils'
import { ChangePasswordParams, FetchCurrent, OtpCheckParams, OtpParams, SigninParams } from './types'
import { Agency } from '@ezio/features/agencies/types'
import { User } from '@ezio/features/users/types'

export const useAuthStore = defineStore('auth', () => {
    // const { api } = useEzioNodeAPI()
    const { api } = useEzioAPI()
    const { ws } = useEzioWs()

    const user = ref<User>(null)
    const agency = ref<Agency>(null)

    const isLoggedIn = computed(() => {
        const tokensStore = useTokensStore()
        return !!tokensStore.tokens.client
    })

    const autorisationLevel = computed(() => {
        if (user.value?.isSuperAdmin) {
            return 9
        }
        if (isLoggedIn.value) {
            // if (user.value.id === '1') {
            //     return 1
            // }
            // // SCA logged
            // if (isSCALogged) {
            //     return 11
            // }

            // // SCA onboarded
            // if (isSCA) {
            //     return 10
            // }

            // is KYC verified
            if (user.value?.kycReview === 2) {
                return 9
            }

            // simple authentication
            return 1
        }
        // not authenticated
        return 0
    })

    const signin = async (data: SigninParams) => {
        try {
            const payload = Object.assign({}, data)

            const response = await api.post('auth/sign_in', payload, {
                apiVersion: ''
            })

            if (response.data.id) {
                store(response.data)
            }

            return response.data
        } catch (err: any) {
            throw new BaseError({
                title: 'Erreur',
                message: err.message
            })
        }
    }
    const signup = async (data: any) => {
        try {
            const { firstname, lastname, email, password } = data
            const response = await api.post(
                'auth',
                {
                    firstname,
                    lastname,
                    email,
                    password
                },
                {
                    apiVersion: ''
                }
            )
            if (response.data.id) {
                store(response.data)
            }
            ws.connect()
            return response.data
        } catch (err: any) {
            throw new BaseError({
                title: 'Erreur',
                message: err.message
            })
        }
    }

    const fetch = async (params: FetchCurrent = null) => {
        try {
            const response = await api.get('current', params)
            store(response.data)
            return response.data
        } catch (error) {
            //
        }
    }

    const resetPassword = async (data: { email: string }) => {
        await api.post('users/reset_password', data)
    }
    const changePassword = async (data: ChangePasswordParams) => {
        await api.post('users/change_password', data)
    }

    const otp = async (data: OtpParams) => {
        await api.get('guests/otp', data)
    }

    const otpCheck = async (data: OtpCheckParams) => {
        const { value, smsCode } = data
        const response = await api.post(
            'guest_auth/sign_in',
            {
                value,
                otp_code: smsCode
            },
            {
                apiVersion: ''
            }
        )

        return response.data
    }

    const store = (data: any) => {
        user.value = Object.assign({}, data)
    }

    const reset = () => {
        user.value = null
    }

    return {
        user,
        agency,
        isLoggedIn,
        autorisationLevel,
        fetch,
        signin,
        signup,
        changePassword,
        resetPassword,
        otp,
        otpCheck,
        store,
        reset
    }
})
