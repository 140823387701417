<template>
    <div class="alert-center">
        <button @click="isOpen = !isOpen" class="icon">
            <FeatherIcon name="bell" />
            <span v-if="unreadNumber > 0" class="badge">{{ unreadNumber }}</span>
        </button>

        <FloatingBox class="bottom p7" v-if="isOpen" @dismiss="dismiss">
            <template #content>
                <BaseTable class="list">
                    <template #title>
                        <h2>Notifications</h2>
                    </template>
                    <template #keys>
                        <th class="td-date">Date</th>
                        <th class="td-message">Message</th>
                    </template>
                    <template #values>
                        <tr v-for="alert in warningAlerts" :class="{ 'is-read': alertsStore.isRead(alert.createdAt) }">
                            <td>{{ dayjs(alert.createdAt).format('DD-MM-YYYY') }}</td>
                            <td>{{ alertCodes[alert.code].description }}</td>
                        </tr>
                    </template>
                    <template #empty v-if="warningAlerts.length < 1">Aucune notification</template>
                </BaseTable>
            </template>
        </FloatingBox>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import { useAlertsStore } from '../store'
import { alertCodes } from '../codes'

const BaseTable = defineAsyncComponent(() => import('@ezio/ui/content/BaseTable.vue'))
const FloatingBox = defineAsyncComponent(async () => (await import('@ezio/ui')).FloatingBox)

const alertsStore = useAlertsStore()

const isOpen = ref(false)
const unreadNumber = computed(() => useAlertsStore().unreadNumber)
const warningAlerts = computed(() => alertsStore.warningAlerts)

onMounted(async () => {
    await useAlertsStore().fetchCollection()
})

const dismiss = () => {
    isOpen.value = false
    // useUsersStore().notificationsOpenedAt()
}
</script>

<style lang="scss" scoped>
.alert-center {
    position: relative;
}
.feather {
    font-size: 16px !important;
    margin-right: 14px;
}
.icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    @media screen and (max-width: 799px) {
        display: none;
    }

    .badge {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 23px;
        height: 23px;
        font-size: 0.75rem;
        background: var(--c-color-warning);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    svg {
        font-size: 20px;
        color: #2c3e50;
    }
}

.list {
    overflow: scroll;
    height: 300px;
    min-width: 400px;
}
.td-date {
    min-width: 150px;
}
</style>
