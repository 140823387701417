import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useEzioAPI } from '@ezio/services'
import { BaseError } from '@ezio/utils'
import { Wallet } from '@ezio/features/bank/types'
import { useEzioWs } from '@ezio/services'
import { Operations } from '@ezio/features/agencies/types'
import { localStores } from '@ezio/features'

export const useBankStore = defineStore('bank', () => {
    const { api } = useEzioAPI()
    const { ws } = useEzioWs()

    const wallet = ref<Wallet>(null)
    const operations = ref<Operations>(null)
    const pendingPayins = ref<any[]>([])

    const init = () => {
        // ws.on('balance:update', (payload) => {
        //     console.log('balance update', payload)
        //     // fetch(payload.agency_id)
        // })
    }

    const fetch = async (agencyId?: string) => {
        if (!agencyId) {
            agencyId = localStores.currentStore?.agencyId
        }
        if (!agencyId) return
        try {
            const response = await api.get('agencies/' + agencyId + '/wallet')
            wallet.value = response.data
            return wallet.value
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const fetchOperations = async (cursor: string = null) => {
        const agencyId = localStores.currentStore?.agencyId
        if (!agencyId) return
        const operationsData = await api.get('agencies/' + agencyId + '/wallet/operations', { cursor })
        operations.value = operationsData.data
    }
    const fetchPendingPayins = async (cursor: string = null) => {
        const agencyId = localStores.currentStore?.agencyId
        if (!agencyId) return
        const response = await api.get('agencies/' + agencyId + '/wallet/pending_payins')
        pendingPayins.value = response.data.reverse()
    }

    // const fetchReportsCollection = async (agencyId: string) => {
    //     const reports = await api.get('agencies/' + agencyId + '/wallet/statements')
    //     return reports.data
    // }
    const fetchStatement = async (agencyId: string, month: number, year: number) => {
        const report = await api.get('agencies/' + agencyId + '/wallet/statements', {
            month,
            year
        })
        return report.data
    }

    const reset = async () => {
        wallet.value = null
    }

    return {
        wallet,
        operations,
        pendingPayins,
        init,
        fetch,
        fetchOperations,
        fetchPendingPayins,
        fetchStatement,
        reset
    }
})
